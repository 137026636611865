
import "./checkout-tos-v1"
import "./checkout-order-summary-v1"
import "./checkout-login-v2"
import "./checkout-product-select-v2"
import "./checkout-product-select-bump-v2"
import "./checkout-multiple-payments-v2"
import "./checkout-express-payments-v1"
import "./checkout-multi-step-v2"
import "./checkout-contact-form-v1"
import "./checkout-billing-address-v1"
import "./checkout-shipping-address-v1"
import "./checkout-saved-contact-details-v1"
import "./checkout-saved-billing-address-v1"
import "./checkout-shipping-profile-select-v1"
import "./checkout-saved-multiple-payments-v1"
import "./checkout-submit-button-v1"
import "./checkout-threeds-frame-v1"
import "./checkout-configuration-error-v1"
import "./checkout-loading-v1"
import "./checkout-order-details-v1"
import "@yggdrasil/Checkout/V2/RebillyCheckout/checkout-globals"
import { CheckoutSummary } from '@yggdrasil/Checkout/V2/Services/checkout-summary'
import { create, buildComputed, initializeUtilities } from '@yggdrasil/Checkout/V2/Services/checkout-store'
import { initializeMachine } from '@yggdrasil/Checkout/V2/Services/checkout-machine'
import { loadPAIDeps } from '@yggdrasil/Checkout/V2/Services/checkout-machine'
import { formBySteps } from '@yggdrasil/Checkout/V2/CheckoutMultiStep/V2/checkoutFormUtils'
import { CF2Component } from 'javascript/lander/runtime'

export default class RebillyCheckout extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}








  FORM_STRUCTURE_BY_TOTAL_STEPS = {
    2: [['contact', 'shipping'], ['products', 'shippingOption', 'billing', 'payment', 'tos']],
    3: [['products'], ['contact', 'shipping'], ['shippingOption', 'billing', 'payment', 'tos']]
  }

  FORM_BY_STEP = formBySteps(this.FORM_STRUCTURE_BY_TOTAL_STEPS)

  initialize() {
    // TODO(henrique): <ONE_STORE_PER_CHECKOUT> right now we are saving store as a global object
    // but the goal is having one store per checkout.
    Checkout.isCheckoutResource = globalThis.globalResourceData.resourceName == "checkout"
    const billingFields = this.billingEnabled ? this.billingFields : []
    const enabledPayments = !window.ApplePaySession ? globalResourceData.enabledPayments.filter((v) => v != 'apple-pay') : globalResourceData.enabledPayments
    const enabledExpressPayments = !window.ApplePaySession ? globalResourceData.expressEnabledPayments.filter((v) => v != 'apple-pay') : globalResourceData.expressEnabledPayments
    if (!enabledExpressPayments.includes("apple-pay")) {
      (this.element.querySelectorAll('.elExpressCheckoutForCart') ?? []).forEach((item) => item.remove())
    }
    Checkout.store = create(billingFields, this.shippingFields, enabledPayments, enabledExpressPayments)
    Checkout.computed = buildComputed(Checkout.store, this.contactFields, this.shippingFields, this.totalSteps)
    initializeUtilities(Checkout.store, Checkout.computed)
    initializeMachine({
      hasPhoneNumber: this.contactFields.includes('phone_number'),
      billingFields: billingFields,
      shippingFields: this.shippingFields,
    })
  }

  backfillContactDataFromGarlic_Mutable(contactData) {
    Object.keys(contactData).reduce((acc, field) => {
      if (!contactData[field]) {
        const val = window.cfGarlicUtils.retrieve(field)
        if (field?.length) acc[field] = val
      }
      return acc
    }, contactData)
  }

  updateCheckoutMode(mode) {
    const containerMapping = Object.values(Checkout.CheckoutStates).reduce((acc, item) => {
      acc[item] = this.element.querySelector(`[data-wrapper-checkout-state="${item}"]`)
      return acc
    }, {})

    const currentContainer = containerMapping[mode]

    for (let container of Object.values(containerMapping)) {
      if (container == currentContainer) {
        currentContainer.classList.remove('elHide')
      } else {
        container.classList.add('elHide') 
      }
    }
  }
  
  scrollToFirstVisibleError() {
    // TODO: Shouldn't we first consider the first input with error otherwise it will scroll to the message
    // but the first incorrect field might still be out of the viewport
    const $errorWrapper = $('[data-error-container="active"]:visible:first').closest('[data-error-wrapper]')
    const $checkoutFormError = $('.elCheckoutFormErrors:visible:first')
    if ($errorWrapper.length > 0) {
      $('html, body').stop(true,true).animate({
          scrollTop: $errorWrapper.offset().top - 100
      }, 500)
    } else if ($checkoutFormError.length > 0) {
        $('html, body').stop(true,true).animate({
          scrollTop: $checkoutFormError.offset().top - 100
      }, 500)
    }
  }

  mount() {
    const contactData = this.contactFields.reduce((acc, field) => {
      acc[field] = Checkout.initialContactData[field]
      return acc
    }, {})
    this.backfillContactDataFromGarlic_Mutable(contactData)

    ;[Checkout.computed.checkoutCart, Checkout.store.billing, Checkout.store.shipping, Checkout.store.shippingOption].forEach((store) => {
      store?.listen(() => {
        CheckoutSummary.sendOrderPreview()
      })
    })

    if (!this.disableOTP) {
      Checkout.auth.computed.requireLogin.listen(requireLogin => {
        if (requireLogin) {
          // hide checkout form
          this.element.querySelector(`[data-wrapper-checkout-state="${Checkout.store.checkout.mode.get()}"]`)
            .classList.add('elHide')
  
        } else {
          // restore checkout form
          this.updateCheckoutMode(Checkout.store.checkout.mode.get())
        }
      })
    }
    Checkout.store.checkout.mode.listen((mode) => {
      this.updateCheckoutMode(mode)
    })
    
    this.listenAndUpdateCheckoutErrors()
    
    // TODO: checkout component could have its local store so that if/when we support multiple checkouts,
    // the value we store here doesnt conflict with other checkout elements.
    Checkout.store.tos.present.set(this.showTos);

    Checkout.store.state.listen((state) => {
      if ([Checkout.StoreStates.START, Checkout.StoreStates.INITIALIZING, Checkout.StoreStates.INITIALIZED].includes(state)) {
        this.element.querySelector('.elSpinnerWrapper').dataset.loader = true
        if (state === Checkout.StoreStates.INITIALIZED) {
          Checkout.store.contact.set(contactData)
        }
      } else {
        if (this.element.querySelector('[data-page-element="CheckoutConfigurationError/V1"]').querySelector('.elHide')) {
          delete this.element.querySelector('.elSpinnerWrapper').dataset.loader 
        }
      }
    })

    this.element.querySelectorAll('[href="#!checkout-as-guest"]').forEach((el) => {
      el.addEventListener('click', (evt) => {
        evt.preventDefault()
        Checkout.store.checkout.mode.set('guest')
      })
    })


    Checkout.computed.hasShippingEnabled.subscribe(hasShippingEnabled => {   
      if (!hasShippingEnabled && Checkout.store.shippingOption.value) {
        // clear any selected shipping option if shipping is no longer enabled
        Checkout.store.shippingOption.set(null)
      }
    })
    Checkout.computed.hideShipping.subscribe(hideShipping => {
      this.element.querySelector('.elCheckoutWrapper').setAttribute('data-shipping-enabled', hideShipping ? 'false' : 'true')      
    })

    Checkout.store.checkout.step.listen((step) => {
      this.addPAIDeps(step)
      this.collectLeads()
    })

    // Initialize checkout after all children components are mounted
    Checkout.store.state.set(Checkout.StoreStates.INITIALIZING)
    const checkoutOrderSummaries = this.element.querySelectorAll('[data-page-element="CheckoutOrderSummary/V1"]')
    const pageOrderSummaries = document.querySelector('[data-page-element="CheckoutOrderSummary/V1"].elCheckout')
    if (pageOrderSummaries) {
      checkoutOrderSummaries.forEach((el) => {
        el.style.display = 'none'
      })
    }

    this.element.querySelectorAll('input, select').forEach((el) => {
      el.addEventListener('focus', () => {
        Checkout.store.submitting.set({state: Checkout.SubmittingStates.IDLE})
      })
    })

    const trackableStores = [
      Checkout.store.contact,
      Checkout.store.shipping,
      Checkout.store.billing,
      Checkout.store.billingSameAsShipping,
      Checkout.store.tos.accepted,
      Checkout.store.shippingOption,
      // Checkout.store.payment.state,
      Checkout.store.payment.id,
      Checkout.store.payment.type,
      Checkout.store.payment['payment-card'].events,
      Checkout.store.payment['payment-card'].token,
      Checkout.store.payment.paypal.state,
      Checkout.store.payment.paypal.token,
      Checkout.computed.checkoutCart,
    ]
    trackableStores.forEach((store) => {
      store.listen((v) => {
        Checkout.store.submitting.set({state: Checkout.SubmittingStates.IDLE})
      })
    })

    Checkout.computed.isOTORetrialPayment.listen((isOTORetrialPayment) => {
      if (isOTORetrialPayment) {
        this.element.querySelector('.elCheckoutOtoPayment').classList.remove('elHide')
      }
    })

    this.getComponents("CheckoutMultiStep/V2").forEach((component) => {
      component.on('validateStep', (step) => {
        const valid = this.validateStep(step)
        if(!valid) {
          this.scrollToFirstVisibleError()
        }
        return valid
      })
    })

    this.getComponents("CheckoutSubmitButton/V1").forEach((component) => {
      component.on('onValidationError', this.scrollToFirstVisibleError)
    })

  }

  validateStep(step) {
    const currentStep = Checkout.store.checkout.step.get()
    const currentFormStructure = this.FORM_STRUCTURE_BY_TOTAL_STEPS[this.totalSteps][currentStep]
    
    const errors = currentFormStructure.reduce((acc, formName) => {
      Checkout.store.showAllErrors[formName].set(true)

      const error = Checkout.computed.errorsByName[formName].get()
      if (Checkout.utils.hasErrors(error)) {
        acc.push(error)
      }
      return acc
    }, [])

    return errors.length == 0
  }

  listenAndUpdateCheckoutErrors() {
    Object.entries(Checkout.computed.errorsByName).forEach(([name, computed]) => {
      computed.subscribe((error) => {
        Object.values(Checkout.CheckoutStates).forEach((mode) => {
          let formStructure = []
          let currentErrorElement = null
          if (mode == 'guest' && this.totalSteps != 1) {
            const currentStep = Checkout.store.checkout.step.get()
            let currentStepWithError = currentStep
            currentErrorElement = this.element.querySelector('.elMultiStepBody[data-step-state="active"]').querySelector('.checkout-general-errors-wrapper')
            const stepForField = this.FORM_BY_STEP[this.totalSteps][name]
            if (stepForField != undefined) {
              if (Checkout.utils.hasErrors(error) && stepForField < currentStep) {
                Checkout.store.checkout.step.set(stepForField)
                this.scrollToFirstVisibleError()
              }
              currentErrorElement = this.element.querySelector(`.elMultiStepBody[data-step-number="${stepForField + 1}"]`).querySelector('.checkout-general-errors-wrapper')
              currentStepWithError = stepForField
            }
            formStructure = this.FORM_STRUCTURE_BY_TOTAL_STEPS[this.totalSteps][currentStepWithError]

          } else {
            formStructure = ['shippingOption', 'products', 'tos', 'payment', 'billing']
            currentErrorElement = this.element.querySelector(`[data-wrapper-checkout-state="${mode}"]`).querySelector('.checkout-general-errors-wrapper')
          }
          
          const errors = formStructure.reduce((acc, formName) => {
            const error = Checkout.computed.errorsByName[formName].get()
            if (Checkout.utils.hasErrors(error)) {
              acc.push({name: formName, errors: error})
            }
            return acc
          }, [])
          if (!errors || errors?.length == 0) {
            currentErrorElement.classList.add('elHide')
          } else {
            const error = errors[0]
            if (error.errors?.globalErrors?.[0]?.message) {
              const errorMessage = error.errors.globalErrors[0].message
              currentErrorElement.querySelector('span').innerHTML = errorMessage
              currentErrorElement.classList.remove('elHide')
            } else {
              currentErrorElement.classList.add('elHide')
            }
          }
        })
      })
    })
  }

  collectLeads() {
    const currentStep = Checkout.store.checkout.step.get()
    const currentFormStructure = this.FORM_STRUCTURE_BY_TOTAL_STEPS[this.totalSteps]?.[currentStep]
    if (currentFormStructure?.includes('contact')) {
      const contactDetails = Checkout.store.contact.get()
      window.CFDispatchEvent(window.CFEvents.FORM_SUBMITTED, {contact: contactDetails})
      fetch('/user_pages/api/contacts', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(contactDetails),
      }).then((response) => {
        if (response.ok) {
          window.CFDispatchEvent(window.CFEvents.FORM_SUBMITTED_OK, {contact: contactDetails})
        }
      })
    }
  }

  addPAIDeps(nextStep) {
    if (this.FORM_STRUCTURE_BY_TOTAL_STEPS[this.totalSteps][nextStep].includes('payment')) {
      loadPAIDeps()
    }
  }



}

window["RebillyCheckout"] = RebillyCheckout

